import React from 'react';
import exampleImg from '../img/example.png';
import { Image } from 'react-bootstrap';
const AboutInfo = () => {
  // page content

  const aboutText = `Welcome to OtisFuse AI Chat AI, where you can experience parody conversations with any person known to the internet. Our platform uses advanced AI technology to emulate the personalities of famous individuals through text messaging. Engage in entertaining and amusing discussions with the AI-generated versions of your favorite celebrities, historical figures, fictional characters, and public personalities.

  Legal Disclaimer:
  
  Please note that the characters represented on our website are purely fictional, AI-generated parodies and are intended for entertainment, humor, and educational purposes only. They do not represent the real people they resemble, nor are they endorsed or affiliated with them in any way. OtisFuse AI Chat AI is not responsible for any misunderstandings, misinterpretations, or any consequences that may arise from the use of our platform. Our intent is solely to provide entertainment and enjoyment to our users.
  
  All trademarks, service marks, trade names, trade dress, product names, and logos appearing on the site are the property of their respective owners. Any rights not expressly granted herein are reserved.
  
  By using OtisFuse AI Chat AI, you acknowledge and agree to these terms, and you agree to hold OtisFuse AI Chat AI, its owners, operators, employees, and affiliates harmless from any liability, claims, or damages arising from your use of the platform or any content generated by the AI.
  
  OtisFuse AI Chat AI reserves the right to modify, suspend, or discontinue the platform, its features, or any content at any time without prior notice. We also reserve the right to limit, restrict, or prohibit access to the platform or any of its features at our sole discretion.
  
  In addition to the above terms, users of OtisFuse AI Chat AI are solely responsible for any characters they create and the content of the conversations they engage in. Users are required to direct the conversations in a manner that is consistent with our terms of use. OtisFuse AI Chat AI does not assume responsibility for any topics or discussions that may violate the terms of use as a result of user input or direction. By using our platform, you acknowledge that you are accountable for the conversations you initiate and the topics you choose to discuss, and you agree to abide by our terms of use.

  Please remember that the characters and conversations on our platform are for entertainment, humor, and educational purposes only, and are not meant to cause harm or promote inappropriate content. In the event of any violation of our terms of use, we reserve the right to take appropriate action, which may include suspending or terminating user access to our platform.
  
  By using OtisFuse AI Chat AI, you acknowledge and agree to these terms, and you agree to hold OtisFuse AI Chat AI, its owners, operators, employees, and affiliates harmless from any liability, claims, or damages arising from your use of the platform or any content generated by the AI.

  Enjoy your conversations and have a great time exploring the amusing world of AI-generated personalities!`

  return (
    <div>
      <center>
        <Image
          src={exampleImg}
          alt=""
          rounded
          className="img-fluid me-2"
          style={{
            border: "2px solid #000", // Change the border width and color as needed
            borderRadius: "4px" // Adjust the border radius if necessary
          }}
        />
      </center>
      <hr />
      <center>
        {aboutText.split('\n').map((line, index) => (
          <React.Fragment key={index}>
            {line}
            <br />
          </React.Fragment>
        ))}
      </center>
      <hr />
      <center>
        <p>
          <a href="https://beta.openai.com/docs/usage-policies">
            See the following disclaimer and usage from OpenAI.
          </a>
        </p>
      </center>
      <div className='starter-template text-center mt-5'>
        <p className='lead text-capitalize'>
          <a href="https://store.steampowered.com/search/?publisher=Otis%20Fuse%20Productions">
            Created by Otis Fuse Productions
          </a>
        </p>
      </div>
    </div>
  )
}

export default AboutInfo;